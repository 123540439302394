import { useState, useEffect } from "react";
import axios from "../../axios";
import { env } from "../../config";

export const useGetRecordedCourseDetails = (id: number) => {
  const [recordedCourse, setRecordedCourse] = useState<RecordedCourse>();
  const [recordedCourseLoading, setRecordedCourseLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setRecordedCourseLoading(true);
      if (id) {
        const { data } = await axios.get<RecordedCourse>(
          `${env.API_URL}/recordedCourses/${id}?projection=libraryDetailsView`
        );
        setRecordedCourse(data);
        setRecordedCourseLoading(false);
      }
    })();
  }, [id]);
  return {
    recordedCourse,
    setRecordedCourse,
    recordedCourseLoading,
  };
};
