import axios from "../../axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CategoryChips,
  LibraryNavbar,
  Modal,
  PageContainer,
  RoundedButton,
  SearchInput,
  SearchWrapper,
  Select,
  SelectLabel,
  SelectWrapper,
  useModal,
  WebinarCard,
  WebinarsGrid,
} from "../../components";
import { env } from "../../config";
import { AuthContext } from "../../context";
import { getYoutubeThumbnail } from "../../helpers";
import { useGetCategories, useGetRecordedCourses } from "../../services";
import { HeroBannerForm } from "../heroBannerForm";
import styled from "styled-components";

const Disclaimer = styled.p`
  color: #aaaaaa;
  margin-bottom: 20px;
`

export const RecordedCoursesPage: React.FC = () => {
  let navigate = useNavigate();

  const { token } = useContext(AuthContext);

  const { recordedCourses, setCategoryId, refreshCourses, setSearch } =
    useGetRecordedCourses({
      projection: "libraryView",
    });

  const { open, description, title, openModal, acceptButton, onClose } =
    useModal({});
  const { categories, setforceRefresh } = useGetCategories();
  const [disabledFormElements, setDisabledFormElements] = useState(true);
  const [inputValue, setInputValue] = useState<string>('');

  const onChangeCategory: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const categoryId = parseInt(event.target.value);
    if (categoryId === 0) setCategoryId(null);
    else setCategoryId(categoryId);
  };

  const deleteCourse = async (id: number) => {
    await axios.delete(`${env.API_URL}/recordedCourses/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    refreshCourses();
  };

  const onDeleteCategory = async (index: Number) => {
    await axios.delete<PostCourseResponse>(
      `${env.API_URL}/delete/${index}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setforceRefresh(true);
  }

  const onClickSaveCategory = async () => {
    if (inputValue.trim() !== '') {
      const category = { name: inputValue };
      await axios.post<PostCourseResponse>(
        `${env.API_URL}/post/categories`,
        category,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setInputValue('');
      setforceRefresh(true);
    }
  }

  const handleRemoveCourse = (cb: () => void) => () => {
    openModal({
      title: "Eliminar curso",
      description: "¿Seguro que desea eliminar el curso?",
      acceptButtonTitle: "Eliminar",
      cb,
    });
  };

  useEffect(() => {
  }, [categories]);

  return (
    <>
      <Modal
        acceptButton={acceptButton}
        open={open}
        title={title}
        description={description}
        onClose={onClose}
      />
      <LibraryNavbar />
      <PageContainer contentStyle={{ padding: "60px" }}>
        <h2 style={{textAlign: "center", marginBottom: "20px"}}>
          Hero Banner
        </h2>
        <Disclaimer><small>* Tenga en cuenta que al elegir una única imagen, esta se mostrará como un Hero banner. En caso de seleccionar varias imágenes, se presentarán en un carrusel.</small></Disclaimer>
        <HeroBannerForm path={"biblioteca"}/>
        <h2 style={{textAlign: "center", marginBottom: "20px"}}>
          Lista de Categorias
        </h2>
        <CategoryChips
          inputValue={inputValue}
          setInputValue={setInputValue}
          categories={categories || []}
          onClickSaveCategory={onClickSaveCategory}
          disabledFormElements={disabledFormElements}
          setDisabledFormElements={setDisabledFormElements}
          onDeleteCategory={onDeleteCategory} />
        <h2 style={{textAlign: "center", marginBottom: "20px"}}>
          Lista de Cursos
        </h2>
        <SearchWrapper>
          <SelectWrapper>
            <SelectLabel>filtrar por categoría</SelectLabel>
            <Select onChange={onChangeCategory} name="categories">
              <option value="0">Todas</option>
              {categories?.map(({ name, id }, index) => (
                <option key={index} value={id}>{name}</option>
              ))}
            </Select>
          </SelectWrapper>
          <SelectWrapper style={{ flexGrow: 1 }}>
            <SelectLabel>buscar</SelectLabel>
            <SearchInput
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Ingresa lo que quieras buscar"
            />
          </SelectWrapper>
          <div style={{ flexGrow: 1 }} />
          <RoundedButton filled onClick={() => navigate("/curso-grabado")}>
            Crear nuevo curso
          </RoundedButton>
        </SearchWrapper>

        <WebinarsGrid>
          {recordedCourses?.map(
            (
              { category, title, description, youtubeUrl, id, categoryId },
              index
            ) => (
              <WebinarCard
                category={category}
                title={title}
                description={description}
                dark={categoryId % 2 === 0}
                thumbnail={
                  youtubeUrl ? getYoutubeThumbnail(youtubeUrl) : undefined
                }
                onClickDelete={handleRemoveCourse(() => deleteCourse(id))}
                onClickEdit={() => navigate(`/curso-grabado/${id}`)}
                key={index}
              />
            )
          )}
        </WebinarsGrid>
      </PageContainer>
    </>
  );
};
