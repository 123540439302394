import axios from "../../axios";
import { env } from "../../config";
import { TOKEN_STORAGE_KEY } from "../../context";

type LoginResponse = {
  token: string;
};

export const login = async (username: string, password: string) => {
  const response = await axios.post<LoginResponse>(`${env.API_URL}/login`, {
    username,
    password,
  });
  return response.data;
};

export const logout = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  window.location.reload();
};
