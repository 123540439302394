import { useState, useEffect } from "react";
import axios from "../../axios";
import { env } from "../../config";

export const useGetWebinarJamWebinars = () => {
  const [webinars, setWebinars] = useState<WebinarJamWebinar[]>();
  const [webinarsLoading, setWebinarsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setWebinarsLoading(true);
      const {
        data: {
          webinars: _webinars
        },
      } = await axios.get<WebinarJamWebinarsResponse>(
        `${env.API_URL}/students/webinars`
      );
      setWebinars(_webinars);
      setWebinarsLoading(false);
    })();
  }, []);
  return { webinars, setWebinars, webinarsLoading };
};
