import { useState, useEffect } from "react";
import axios from "axios";
import { env } from "../../config";

export const useGetRecordedCourseTags = () => {
  const [recordedCourseTags, setRecordedCourseTags] = useState<RecordedCourseTag[]>();
  const [recordedCourseTagsLoading, setRecordedCourseTagsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setRecordedCourseTagsLoading(true);
      const {
        data: {
          _embedded: { recordedCourseTags: _recordedCourseTags },
        },
      } = await axios.get<RecordedCourseTagsResponse>(
        `${env.API_URL}/recordedCourseTags`
      );
      setRecordedCourseTags(_recordedCourseTags);
      setRecordedCourseTagsLoading(false);
    })();
  }, []);
  return { recordedCourseTags, setRecordedCourseTags, recordedCourseTagsLoading };
};
