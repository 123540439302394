import styled from "styled-components";

export const PageGrid = styled.div`
  display: flex;
  gap: 60px;
  padding-right: 60px;
  padding-bottom: 100px;
`;

export const HomePageGrid = styled.div`
  display: flex;
  gap: 60px;
  padding-bottom: 50px;
`;

export const LateralBar = styled.div`
  width: 300px;
  padding-left: 2px;
`;

export const FormFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
`;

export const HomeFormFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 250px;
  justify-content: flex-end;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CenterGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 60px;
  padding-bottom: 100px;
`;

export const BrowseImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 120px;
  background-color: #f3f8fe;
  border: 2px dashed #a8d1f8;
  border-radius: 8px;
  padding: 0 20px;
  justify-content: center;
  gap: 10px
`;

export const BrowseButton = styled.div`
  background-color: #019af1;
  border-radius: 8px;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
  line-height: 15px;
  text-align: center;
`;

export const SelectedFile = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
`;

export const CarouselForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CarouselSlide = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Slide = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  padding: 3px;
  margin: 5px 0;
`;

export const SlideTitle = styled.h2`
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
`;