import React, { useContext, useEffect, useState } from "react";
import deleteSrc from "../../assets/images/delete.svg";
import { AuthContext } from "../../context";
import { env } from "../../config";
import axios from "axios";
import editSrc from "../../assets/images/edit.svg";
import { useGetBenefits } from "../../services";
import { EditButton } from "../heroBannerForm";

import {
  ActionButton,
  HomeFormFlex,
  HomePageGrid,
  HomeTextArea,
  Input,
  InputLabel,
  InputWrapper,
  Modal,
  RoundedButton,
  useModal,
} from "../../components";

export const BenefitsForm: React.FC = () => {

  //initial values
  const getInitialBenefits = (): BenefitsObj => ({
    title: "",
    benefits: []
  });

  const { token } = useContext(AuthContext);
  const { open, description, title, openModal, acceptButton, onClose } = useModal({});

  const { benefitData, setforceRefresh } = useGetBenefits();
  const [benefitsObj, setBenefitsObj] = useState<BenefitsObj>(getInitialBenefits());
  const [disabledFormElements, setDisabledFormElements] = useState(true);

  useEffect(() => {
    if (benefitData) {
      setBenefitsObj(benefitData);
    }
  }, [benefitData]);

  const onChangeBenefitField = (
    fieldName: string,
    newValue: string,
    benefitIndex?: number
  ) => {
    setBenefitsObj((prevBenefits) => {
      const newBenefits = { ...prevBenefits };
      if (fieldName === 'title') {
        newBenefits.title = newValue;
      } else if (benefitIndex !== undefined) {
        newBenefits.benefits[benefitIndex] = newValue;
      }
      return newBenefits;
    });
  };

  const removeBenefit = (benefitIndex: number) => {
    const updatedBenefits = [...benefitsObj.benefits];
      updatedBenefits.splice(benefitIndex, 1);

      setBenefitsObj({
        ...benefitsObj,
        benefits: updatedBenefits,
      });
  };

  const addBenefit = () => {
    const updatedBenefits = [...benefitsObj.benefits, ""];
    setBenefitsObj({
      ...benefitsObj,
      benefits: updatedBenefits,
    });
  };



  const handleRemoveBenefit = (cb: () => void) => () => {
    openModal({
      title: "Eliminar Beneficio",
      description: "¿Seguro que desea eliminar el beneficio seleccionado?",
      acceptButtonTitle: "Eliminar",
      cb,
    });
  };

  const handlePublish = (cb: () => void) => () => {
    openModal({
      title: "Actualizar Seccion",
      description:
        "Se actualizara la seccion Beneficios \n¿Deseas actualizarlo ahora?",
      acceptButtonTitle: "Publicar",
      cb,
    });
  };

  const handleFormElementsDisabled = () => {
    setDisabledFormElements(!disabledFormElements)
  }

  const onClickSave = async () => {
    await axios.post<PostCourseResponse>(
      `${env.API_URL}/post/benefitSection`,
      benefitsObj,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setDisabledFormElements(!disabledFormElements)
    setforceRefresh(true);
  };

  return (
    <>
      <Modal
        acceptButton={acceptButton}
        open={open}
        title={title}
        description={description}
        onClose={onClose}
      />
      <HomePageGrid>
        <div style={{width: "100%"}}>
          <HomeFormFlex>
            <div style={{
                    border: "1px solid #AAAAAA",
                    width: "auto",
                    height: "auto",
                    padding: "20px",
                  }} >
              <div style={{ marginBottom: "5px" }}>
                <InputLabel>Titulo de la Seccion:</InputLabel>
                  <Input
                  style={{ padding: "8px", width: "100%" }}
                  onChange={(e) =>
                    onChangeBenefitField(
                      "title",
                      e.target.value
                    )
                  }
                  placeholder="Ingresa titulo de la Seccion"
                  value={benefitsObj.title}
                  disabled={disabledFormElements}
                />
              </div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "5px", justifyContent: "center"}}>
            {benefitsObj && benefitsObj.benefits.map((benefit, benefitIndex) => (
              <div
              style={{
                border: "1px solid #AAAAAA",
                flexBasis: "calc(30% - 20px)",
                marginTop: "10px",
                padding: "8px",
                boxSizing: "border-box",
              }}
                key={benefitIndex}
              >
                <InputWrapper>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <InputWrapper style={{ flexGrow: "18" }}>
                        <HomeTextArea
                          style={{ padding: "8px" }}
                          onChange={(e) =>
                            onChangeBenefitField("benefit", e.target.value, benefitIndex)
                          }
                          placeholder="Ingresa nuevo beneficio"
                          value={benefit}
                          disabled={disabledFormElements}
                        />
                    </InputWrapper>
                    <ActionButton
                      onClick={handleRemoveBenefit(() => removeBenefit(benefitIndex))}
                      iconSrc={deleteSrc}
                      status={disabledFormElements}
                      disabled={disabledFormElements}
                    />
                  </div>
                </InputWrapper>
              </div>
            ))}
            </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div
              style={{
                display: "flex",
                gap: "10px"
              }}
              >
                <RoundedButton
                  filled
                  type="button"
                  onClick={addBenefit}
                  status={disabledFormElements}
                  disabled={disabledFormElements}
                >
                  + Agregar beneficio
                </RoundedButton>
                <RoundedButton
                  filled
                  type="button"
                  onClick={handlePublish(() => onClickSave())}
                  status={disabledFormElements}
                  disabled={disabledFormElements}
                  >
                  Publicar
                </RoundedButton>
              </div>
              <EditButton iconSrc={editSrc} onClick={handleFormElementsDisabled}/>
            </div>
          </HomeFormFlex>
        </div>
      </HomePageGrid>
    </>
  );
};