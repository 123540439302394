import { useNavigate } from "react-router-dom";
import { useLocation } from "react-use";
import styled from "styled-components";
import chevronBack from "../../assets/images/chevron-back.svg";
import { logout } from "../../services";
import { PageContainer } from "../PageContainer";
import { RoundedButton } from "../RoundedButton";
import { Subtitle } from "../Typhographies";

const LibraryNavbarWrapper = styled.div`
  background-color: #0b2739;
  height: 90px;
`;

interface LibraryNavbarButtonProps {
  selected?: boolean;
}

const LibraryNavbarButton = styled.button<LibraryNavbarButtonProps>`
  border: none;
  background: none;
  height: 100%;
  color: white;
  font-family: telefonicaBold;
  text-transform: uppercase;
  font-size: 1.3rem;
  ${({ selected }) =>
    selected ? `border-bottom: 4px solid white;` : `opacity: 0.5;`}
  cursor: pointer;
`;

export const LibraryNavbar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <LibraryNavbarWrapper>
      <PageContainer
        style={{ height: "100%" }}
        contentStyle={{ display: "flex", gap: "70px", height: "100%" }}
      >
        <LibraryNavbarButton
          selected={location.pathname === "/home"}
          onClick={() => navigate("/home")}
        >
          Home
        </LibraryNavbarButton>
        <LibraryNavbarButton
          selected={location.pathname === "/"}
          onClick={() => navigate("/")}
        >
          Cursos
        </LibraryNavbarButton>
        <LibraryNavbarButton
          selected={location.pathname === "/proximas-clases"}
          onClick={() => navigate("/proximas-clases")}
        >
          Próximas clases
        </LibraryNavbarButton>
        <div style={{ flexGrow: 1 }} />
        <LibraryNavbarButton selected={false} onClick={() => logout()}>
          Cerrar Sesión
        </LibraryNavbarButton>
      </PageContainer>
    </LibraryNavbarWrapper>
  );
};

const FormNavbarWrapper = styled.div`
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  height: 70px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
`;

const BackButton = styled.button`
  background: url(${chevronBack}) 7px center;
  cursor: pointer;
  background-size: contain;
  height: 30px;
  width: 30px;
  border: none;
`;

interface FormNavbarProps {
  onClickGoBack: () => void;
  title: string;
  onClickSave: () => void;
  onClickPublish: () => void;
  disabled?: boolean;
}

export const FormNavbar: React.FC<FormNavbarProps> = ({
  onClickGoBack,
  title,
  onClickSave,
  onClickPublish,
  disabled,
}) => {
  return (
    <FormNavbarWrapper>
      <div style={{ display: "flex", gap: "20px" }}>
        <BackButton onClick={onClickGoBack} disabled={disabled} />
        <Subtitle>{title}</Subtitle>
      </div>
      <div style={{ display: "flex", gap: "20px" }}>
        <RoundedButton disabled={disabled} filled dark onClick={onClickSave}>
          Guardar sin publicar
        </RoundedButton>
        <RoundedButton disabled={disabled} filled onClick={onClickPublish}>
          Publicar
        </RoundedButton>
      </div>
    </FormNavbarWrapper>
  );
};
