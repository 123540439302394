import { useState, useEffect } from "react";
import axios from "../../axios";
import { env } from "../../config";

export const useGetCourseDetails = (id?: number) => {
  const [course, setCourse] = useState<Course>();
  const [coursesLoading, setCoursesLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setCoursesLoading(true);
      if (id) {
        try {
          const { data } = await axios.get<Course>(
            `${env.API_URL}/courses/${id}?projection=courseDetails`
          );
          setCourse(data);
          setCoursesLoading(false);
        }
        catch(err) {
          console.log(err)
          setCoursesLoading(false);
        }
      }
    })();
  }, [id]);
  return { course, setCourse, coursesLoading };
};
