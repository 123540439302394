import { createContext, useEffect, useState } from "react";
import axios from "../../axios";
import { env } from "../../config";
import { login } from "../../services";

interface IAuthContext {
  token?: string | null;
  isAuthenticated: boolean;
  authenticate: (username: string, password: string) => any;
  signOut: () => void;
}

export const TOKEN_STORAGE_KEY = "token";

const localToken = localStorage.getItem(TOKEN_STORAGE_KEY);

export const AuthContext = createContext<IAuthContext>({
  authenticate: (username, password) => ({}),
  isAuthenticated: false,
  signOut: () => {},
});

export const AuthContextProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(localToken);
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(localToken));

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (token?.length) {
        axios.post(
          `${env.API_URL}/checkAuth`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    }, 1000 * 60 * 30);
    return () => {
      window.clearInterval(interval);
    };
  }, [token]);

  const authenticate = async (username: string, password: string) => {
    const response = await login(username, password);
    setToken(response.token);
    localStorage.setItem(TOKEN_STORAGE_KEY, response.token);
    setIsAuthenticated(true);
  };
  const signOut = () => {
    setToken("");
    setIsAuthenticated(false);
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  };

  return (
    <AuthContext.Provider
      value={{ token, authenticate, isAuthenticated, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};
