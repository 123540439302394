import { useState, useEffect } from "react";
import axios from "../../axios";
import { env } from "../../config";

export const useGetCarousel = (section: string) => {
  const [carouselData, setCarousel] = useState<Carousel>();
  const [carouselLoading, setCarouselLoading] = useState(true);
  const [forceRefresh, setforceRefresh] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      setCarouselLoading(true);
      if (section) {
        try {
          const { data } = await axios.get<Carousel>(
            `${env.API_URL}/carousel/${section}`
          );
          setCarousel(data);
          setCarouselLoading(false);
        } catch (err) {
          console.log(err);
          setCarouselLoading(false);
        }
      }
    };
    fetchData();

    if (forceRefresh) {
      fetchData();
      setforceRefresh(false)
    }
  }, [section, forceRefresh]);

  return { carouselData, setCarousel, carouselLoading, setforceRefresh };
};
