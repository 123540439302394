import { PropsWithChildren } from "react";
import styled from "styled-components";
import { Chip } from "../Chip";
import deleteSrc from "../../assets/images/delete.svg";
import editSrc from "../../assets/images/edit.svg";
import inventorySrc from "../../assets/images/inventory-icon.svg";
import { trim } from "../../helpers";

const WebinarCardWrapper = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  min-height: 400px;
  max-width: 450px;
  min-width: 310px;
  background-color: white;
`;

interface WebinarCardHeaderProps {
  imageUrl?: string;
}

const WebinarCardHeader = styled.div<WebinarCardHeaderProps>`
  height: 250px;
  width: 100%;
  background: ${({ imageUrl }) =>
    imageUrl ? `url(${imageUrl}) no-repeat center center` : "#cbcbcb"};
  background-size: cover;
  padding: 30px;
  padding-bottom: 20px;
  display: flex;
  align-items: flex-end;
  position: relative;
`;

const WebinarCardHeaderGradient = styled.div`
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 25%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const RaisedChip = styled(Chip)`
  z-index: 2;
`;

const WebinarCardContent = styled.div`
  padding: 30px;
  padding-top: 0px;
`;

const WebinarCardTitle = styled.h3`
  font-family: telefonicaBold;
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const WebinarCardText = styled.p`
  font-size: 1rem;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
`;

interface ActionButtonProps {
  dark?: boolean;
  iconSrc?: string;
  status?: boolean;
}

export const ActionButton = styled.button<ActionButtonProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #019af1;
  ${({ dark }) => (dark ? "background-color:#0A2739;" : "")}
  z-index: 1;
  ${({ iconSrc }) =>
    iconSrc
      ? `
    background-image: url(${iconSrc});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
  `
      : ``}
    ${({ status }) => (status ? "background-color:#b0e2ff;" : "cursor: pointer")}
`;

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  category?: string;
  title: string;
  description: string;
  dark?: boolean;
  thumbnail?: string;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onClickDownloadUsers?: () => void;
}

export const WebinarCard: React.FC<PropsWithChildren<IProps>> = ({
  category,
  title,
  description,
  dark,
  thumbnail,
  onClickEdit,
  onClickDelete,
  onClickDownloadUsers,
  ...restProps
}) => {
  return (
    <WebinarCardWrapper {...restProps}>
      <WebinarCardHeader imageUrl={thumbnail}>
        <ActionButtonsWrapper>
          <ActionButton iconSrc={deleteSrc} dark onClick={onClickDelete} />
          <ActionButton iconSrc={editSrc} onClick={onClickEdit} />
          {onClickDownloadUsers && (
            <ActionButton
              iconSrc={inventorySrc}
              dark
              onClick={onClickDownloadUsers}
            />
          )}
        </ActionButtonsWrapper>
        <WebinarCardHeaderGradient />
        {category && <RaisedChip dark={dark}>{category}</RaisedChip>}
      </WebinarCardHeader>
      <WebinarCardContent>
        <WebinarCardTitle>{trim(title,100)}</WebinarCardTitle>
        <WebinarCardText>{trim(description,300)}</WebinarCardText>
      </WebinarCardContent>
    </WebinarCardWrapper>
  );
};
