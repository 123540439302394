import styled from "styled-components";

interface IProps {
  filled?: boolean;
  wide?: boolean;
  animated?: boolean;
  white?: boolean;
  dark?: boolean;
  status?: boolean;
}

export const RoundedButton = styled.button<IProps>`
  border: none;

  padding-left: 10px;
  padding-right: 10px;
  min-width: initial;
  font-size: 0.8rem;
  background-color: none;
  background: none;
  transition: color 0.2s, background-color 0.2s;
  ${({ filled, animated }) =>
    filled
      ? `
  background-color: #019af1;
  color: white;
  ${
    animated
      ? `&:hover {
    color: #0A2739;
    background-color: white;
  }`
      : ""
  }
  `
      : `
      border: 1px solid #0B2738;
      color: #0B2738;
  `}
  ${({ wide }) =>
    wide
      ? `
  min-width: 320px;
  font-size: 1rem;
  padding-left: 20px;
  padding-right: 20px;`
      : ``}

  ${({ white }) =>
    white
      ? `
      color: #0A2739;
    background-color: white;
      `
      : ``}
      
  ${({ dark }) =>
    dark
      ? `
      background-color: #0A2739;
    color: white;
      `
      : ``}


  

  height: 45px;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "telefonicaBold";

  ${({ status }) => (status ? "background-color:#b0e2ff;" : "cursor: pointer")}
`;

interface FormAppBarButtonProps {
  selected?: boolean;
}

export const FormAppBarButton = styled.button<FormAppBarButtonProps>`
  width: 100%;
  border: 1px solid #707070;
  ${({ selected }) => (selected ? "border-left: 4px solid #019af1;" : "")}
  border-top: none;
  padding: 20px;
  padding-left: 40px;
  background: none;
  font-family: telefonicaBold;
  font-size: 1rem;
  cursor: pointer;
  text-align: left;
`;

export const FileUploadLabel = styled.label`
  border: none;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  z-index: 1;
  font-weight: 500;
  font-size: 1rem;
  background: none;
  text-align: left;
`;

export const HiddenInput = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

export const PlusButton = styled.button<IProps>`
  color: #019af1;
  border: none;
  padding-right: 10px;
  min-width: initial;
  font-size: 16px;
  background-color: none;
  background: none;
  transition: color 0.2s, background-color 0.2s;
`;

export const Checkbox = styled.input`
  width: 18px;
  height: 18px;
  border: 20px;
  &:checked {
    border: none;
    background: url(checkboxSrc) no-repeat center center;
    background-size: contain;
  }
  border-radius: 3px;
  accent-color: #019af1;
`;
