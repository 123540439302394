import React from "react";
import { LibraryNavbar, PageContainer } from "../../components";
import { HeroBannerForm } from "../heroBannerForm";
import { LearningForm } from "../learningForm";
import { BenefitsForm } from "../benefitsForm";
import styled from "styled-components";

export const HomeFormPage: React.FC = () => {
  const Disclaimer = styled.p`
    color: #aaaaaa;
    margin-bottom: 20px;
  `

  return (
    <>
      <LibraryNavbar />
      <PageContainer contentStyle={{ padding: "60px" }}>
        <h2 style={{textAlign: "center", marginBottom: "30px"}}>
          Hero Banner
        </h2>
        <Disclaimer><small>* Tenga en cuenta que al elegir una única imagen, esta se mostrará como un Hero banner. En caso de seleccionar varias imágenes, se presentarán en un carrusel.</small></Disclaimer>
        <HeroBannerForm path={"home"}/>
        <h2 style={{textAlign: "center", marginBottom: "30px"}}>
          Seccion Aprendizaje
        </h2>
        <LearningForm />
        <h2 style={{textAlign: "center", marginBottom: "30px"}}>
          Seccion Beneficios
        </h2>
        <BenefitsForm />
      </PageContainer>
    </>
  );
};
