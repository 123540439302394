import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context";
import { env } from "../../config";
import axios from "axios";
import editSrc from "../../assets/images/edit.svg";
import { useGetSection } from "../../services";
import { EditButton } from "../heroBannerForm";

import {
  HomeFormFlex,
  HomePageGrid,
  Input,
  InputLabel,
  InputWrapper,
  Modal,
  RoundedButton,
  useModal,
} from "../../components";

export const LearningForm: React.FC = () => {

  //initial values
  const getInitialSection = (): SectionObj => ({
    title: "",
    description: ""
  });

  const { token } = useContext(AuthContext);
  const { open, description, title, openModal, acceptButton, onClose } = useModal({});

  const { sectionData, setforceRefresh } = useGetSection();
  const [sectionObj, setSectionObj] = useState<SectionObj>(getInitialSection());
  const [disabledFormElements, setDisabledFormElements] = useState(true);

  useEffect(() => {
    if (sectionData) {
      setSectionObj(sectionData);
    }
  }, [sectionData]);

  const onChangeSectionField = (fieldName: string, newValue: string) => {
    setSectionObj((prevValues) => {
      return {
        ...prevValues,
        [fieldName]: newValue,
      };
    });
  };

  const handlePublish = (cb: () => void) => () => {
    openModal({
      title: "Actualizar Seccion",
      description:
        "Se actualizara la seccion Beneficios \n¿Deseas actualizarlo ahora?",
      acceptButtonTitle: "Publicar",
      cb,
    });
  };

  const handleFormElementsDisabled = () => {
    setDisabledFormElements(!disabledFormElements)
  }

  const onClickSave = async () => {
    await axios.post<PostCourseResponse>(
      `${env.API_URL}/post/section`,
      sectionObj,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setDisabledFormElements(!disabledFormElements)
    setforceRefresh(true);
  };

  return (
    <>
      <Modal
        acceptButton={acceptButton}
        open={open}
        title={title}
        description={description}
        onClose={onClose}
      />
      <HomePageGrid>
        <div style={{width: "100%"}}>
          <HomeFormFlex>
          <div style={{
                    border: "1px solid #AAAAAA",
                    width: "auto",
                    height: "auto",
                    padding: "20px",
                  }}>
          {sectionObj ? (
            <InputWrapper>
              <InputLabel>Titulo de la Seccion:</InputLabel>
                <Input
                  style={{ padding: "8px", width: "100%", marginBottom: "20px" }}
                  onChange={(e) =>
                    onChangeSectionField(
                      "title",
                      e.target.value
                    )
                  }
                  placeholder="Ingresa titulo de la Seccion"
                  value={sectionObj.title}
                  disabled={disabledFormElements}
                />
              <InputLabel>Descripcion de la Seccion:</InputLabel>
                <Input
                  style={{ padding: "8px", width: "100%" }}
                  onChange={(e) =>
                    onChangeSectionField(
                      "description",
                      e.target.value
                    )
                  }
                  placeholder="Descripcion..."
                  value={sectionObj.description}
                  disabled={disabledFormElements}
                />
            </InputWrapper>
          ) :
            null
          }
          </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <RoundedButton
                filled
                type="button"
                onClick={handlePublish(() => onClickSave())}
                status={disabledFormElements}
                disabled={disabledFormElements}
              >
                Publicar
              </RoundedButton>
              <EditButton iconSrc={editSrc} onClick={handleFormElementsDisabled}/>
            </div>
          </HomeFormFlex>
        </div>
      </HomePageGrid>
    </>
  );
};