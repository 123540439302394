import { useEffect, useState } from 'react';
import { SearchWrapper } from '../SearchWrapper';
import { SelectWrapper } from '../Select';
import { Input } from '../Input';
import { RoundedButton } from '../RoundedButton';
import styled from 'styled-components';
import xMark from '../../assets/images/xmark.svg';
import editSrc from "../../assets/images/edit.svg";
import { Modal, useModal } from '../Modal';
import { EditButton } from '../../pages';


interface EditButtonProps {
  iconSrc?: string;
  status?: boolean
}

const Chip = styled.div<EditButtonProps>`
  color: white;
  border-radius: 20px;
  padding: 10px 15px;
  margin: 0 5px 5px 0;
  display: flex;
  align-items: center;
  ${({ status }) => status ? "background-color:#b0e2ff;" : "background-color:#019af1;" }
`;

const Text = styled.span`
  cursor: default;
`;

const RemoveButton = styled.button<EditButtonProps>`
  width: 10px;
  height: 10px;
  border: none;
  z-index: 1;
  ${({ iconSrc }) => iconSrc ?
  `
    background-image: url(${iconSrc});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    margin-left: 5px;
  `
  : ``}
  ${({ status }) => status ? "background-color:#b0e2ff;" : "background-color:#019af1;" }
`;

const ContainerChip = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 90px;
`;

export const CategoryInput = styled(Input)`
  height: 30px;
  width: 450px;
  padding-left: 5px;
  border: 1px solid #707070;
  margin: 0px;
  margin-top: 0.5px;
`;

interface CategoryChipsProps {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  categories: Category[];
  onClickSaveCategory: () => void;
  disabledFormElements: boolean;
  setDisabledFormElements: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteCategory: (index: number) => void;
}

export const CategoryChips: React.FC<CategoryChipsProps> = ({
  inputValue,
  setInputValue,
  categories,
  onClickSaveCategory,
  disabledFormElements,
  setDisabledFormElements,
  onDeleteCategory,
}) => {
  const { open, description, title, openModal, acceptButton, onClose } = useModal({});
  const [chips, setChips] = useState<Category[]>([]);

  useEffect(() => {
    if (categories) {
      setChips(categories);
    }
  }, [categories]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleFormElementsDisabled = () => {
    setDisabledFormElements(!disabledFormElements);
  };

  const handleDelete = (cb: () => void) => () => {
    openModal({
      title: "Eliminar Categoria",
      description: "¿Deseas eliminar la categoria seleccionada?",
      acceptButtonTitle: "Eliminar",
      cb,
    });
  };

  const handlePublish = (enableForm: boolean) => () => {
    openModal({
      title: "Agregar Categoria",
      description:
        "La categoría será añadida a la lista actual. \n¿Deseas agregarla ahora?",
      acceptButtonTitle: "Argregar",
      cb: () => {
        if (enableForm) {
          onClickSaveCategory();
        }
        setDisabledFormElements(!enableForm);
      },
    });
  };

  return (
    <>
      <Modal
        acceptButton={acceptButton}
        open={open}
        title={title}
        description={description}
        onClose={onClose}
      />
      <div style={{ paddingBottom: "50px" }}>
        <SearchWrapper style={{ alignItems: "center", marginBottom: "30px" }}>
          <SelectWrapper style={{ flexGrow: 1 }}>
            <CategoryInput
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Nueva categoria"
              disabled={disabledFormElements}
            />
          </SelectWrapper>
          <div style={{ flexGrow: 1 }} />
          {disabledFormElements ? (
            <EditButton iconSrc={editSrc} onClick={handleFormElementsDisabled} />
          ) : (
            <RoundedButton
              filled
              onClick={() => {
                if (inputValue.trim() !== "") {
                  handlePublish(true)();
                } else {
                  handlePublish(false)();
                }
              }}
            >
              Agregar
            </RoundedButton>
          )}
        </SearchWrapper>
        <ContainerChip>
          {chips?.map((chip, index) => (
            <Chip
              key={index}
              status={disabledFormElements}
            >
              <Text>{chip.name}</Text>
              <RemoveButton
                onClick={handleDelete(() => onDeleteCategory(chip.id))}
                iconSrc={xMark}
                disabled={disabledFormElements}
                status={disabledFormElements}
              />
            </Chip>
          ))}
        </ContainerChip>
      </div>
    </>
  );
};
