import { PropsWithChildren } from "react";
import styled from "styled-components";

const BannerCardWrapper = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
`;

interface BannerCardProps {
  imageUrl?: string;
}

const BannerCardBackground = styled.div<BannerCardProps>`
  min-height: 100px;
  width: 100%;
  background: ${({ imageUrl }) =>
    imageUrl ? `url(${imageUrl}) no-repeat center center` : "#cbcbcb"};
  background-size: cover;
  padding: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
`;

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  thumbnail: string;
}

export const BannerCard: React.FC<PropsWithChildren<IProps>> = ({
  thumbnail,
  ...restProps
}) => {
  return (
    <BannerCardWrapper {...restProps}>
      <BannerCardBackground imageUrl={thumbnail}>
      </BannerCardBackground>
    </BannerCardWrapper>
  );
};
