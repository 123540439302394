import imageCompression from "browser-image-compression";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";

import styled from "styled-components";
import deleteSrc from "../../assets/images/delete.svg";
import axios from "../../axios";
import {
  ActionButton,
  Checkbox,
  DeleteButton,
  FileUploadLabel,
  FormAppBarButton,
  FormFlex,
  FormNavbar,
  HiddenInput,
  Input,
  InputLabel,
  InputWrapper,
  LateralBar,
  Modal,
  PageGrid,
  PlusButton,
  RoundedButton,
  TextArea,
  useModal,
} from "../../components";
import { env } from "../../config";
import { AuthContext } from "../../context";
import { useGetCourseDetails } from "../../services/useGetCourseDetails";
import { useGetWebinarJamWebinars } from "../../services/useGetWebinarJamList";

const getInitialTeacher = (): Teacher => ({
  name: "",
  title: "",
  linkedinUrl: "",
});

const getInitialModule = (): Module => ({
  name: "",
  description: "",
  teachers: [getInitialTeacher()],
  moduleDate: "",
});

const getInitialCourseDetails = (): Course => ({
  title: "",
  description: "",
  objective: "",
  mode: "online",
  schedule: [],
  certificate: "",
  certified: true,
  modality: "",
  inscription: true,
});

const FileDisplay: React.FC<{
  onClickDelete: () => void;
  children: React.ReactNode;
}> = ({ onClickDelete, children }) => (
  <div
    style={{
      gap: "10px",
      width: "auto",
      height: "48px",
      display: "flex",
      marginTop: "15px",
      border: "1px solid #019af1",
      alignItems: "center",
    }}
  >
    <FileUploadLabel
      style={{ cursor: "initial", textDecoration: "none", color: "#019af1" }}
    >
      {children}
    </FileUploadLabel>
    <DeleteButton onClick={onClickDelete} />
  </div>
);
const CustomDatePicker = styled(DatePicker)`
  border: 1px solid #aaaaaa;
  width: 600px;
  height: 40px;
  padding: 20px;
  font-size: 15px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #019af1;
  }
`;

export const CourseFormPage: React.FC<{ editMode?: boolean }> = ({
  editMode,
}) => {
  const navigate = useNavigate();

  const [selectedForm, setSelectedForm] = useState<"info" | "modules">("info");

  const [courseDetails, setCourseDetails] = useState<Course>(
    getInitialCourseDetails()
  );

  const [courseImage, setCourseImage] = useState<File>();

  const [teacherImages, setTeacherImages] = useState<
    Record<number, Record<number, File>>
  >([]);

  const { courseId } = useParams();

  const { course } = useGetCourseDetails(parseInt(courseId || "1"));
  const { webinars, webinarsLoading } = useGetWebinarJamWebinars();
  //const [value, onChange] = useState(new Date());

  const webinarsSelectOptions = webinars?.map(
    ({ name, webinar_id, schedules }) => ({
      label: name,
      value: webinar_id,
      schedules,
    })
  );

  const selectedWebinar = webinarsSelectOptions?.find(
    ({ value }) => value === courseDetails?.webinarJamId
  );

  const [modules, setModules] = useState<Module[]>([getInitialModule()]);

  useEffect(() => {
    if (course && editMode) {
      setModules(course.courseModules || []);
      setCourseDetails(course);
    }
  }, [course, editMode]);

  const onChangeCourseField = (
    fieldName: string,
    newValue: string | number | boolean | string[]
  ) => {
    setCourseDetails({ ...courseDetails, [fieldName]: newValue });
  };

  const onChangeModuleField = (
    fieldName: string,
    moduleIndex: number,
    newValue: string
  ) => {
    let newModules = [...modules];
    newModules[moduleIndex] = {
      ...newModules[moduleIndex],
      [fieldName]: newValue,
    };
    setModules(newModules);

    console.log(newModules);
  };

  const onChangeTeacherField = (
    fieldName: string,
    moduleIndex: number,
    teacherIndex: number,
    newValue: string
  ) => {
    let newTeacher = {
      ...modules[moduleIndex].teachers[teacherIndex],
      [fieldName]: newValue,
    };
    let newModules = [...modules];
    newModules[moduleIndex].teachers[teacherIndex] = newTeacher;
    setModules(newModules);
  };

  const addModule = () => {
    setModules([...modules, getInitialModule()]);
  };

  const removeModule = (index: number) => {
    setModules(modules.filter((el, idx) => idx !== index));
  };

  const addTeacher = (moduleIndex: number) => {
    let newModules = [...modules];
    newModules[moduleIndex].teachers.push(getInitialTeacher());
    setModules(newModules);
  };

  const removeTeacher = (moduleIndex: number, teacherIndex: number) => {
    deleteTeacherImage(moduleIndex, teacherIndex);
    let newTeachers = [...modules[moduleIndex].teachers].filter(
      (el, idx) => idx !== teacherIndex
    );
    let newModules = [...modules];
    newModules[moduleIndex].teachers = newTeachers;
    setModules(newModules);
  };

  const { token } = useContext(AuthContext);
  const onClickSave = async () => {
    console.log(">>>>>  courseDetails ", courseDetails);
    const {
      data: { id: courseId, courseModules },
    } = await axios.post<PostCourseResponse>(
      `${env.API_URL}/post/course`,
      { ...courseDetails, courseModules: modules },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (courseImage?.size) {
      const formData = new FormData();
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedImage = await imageCompression(courseImage, options);
      formData.append("file", compressedImage);
      await axios.post<PostCourseResponse>(
        `${env.API_URL}/post/course/${courseId}/image`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    }
    if (Object.keys(teacherImages).length) {
      const promises: Promise<any>[] = [];
      Object.entries(teacherImages).forEach(([moduleIndex, teachers]) =>
        Object.entries(teachers).forEach(
          ([teacherIndex, teacherProfileImageFile]) =>
            promises.push(
              new Promise(async (resolve) => {
                const teacherId = _.get(
                  courseModules,
                  `${moduleIndex}.teachers.${teacherIndex}.id`
                );
                if (teacherId) {
                  try {
                    const formData = new FormData();
                    const options = {
                      maxSizeMB: 0.5,
                      maxWidthOrHeight: 500,
                      useWebWorker: true,
                    };
                    const compressedTeacherImage = await imageCompression(
                      teacherProfileImageFile,
                      options
                    );
                    formData.append("file", compressedTeacherImage);
                    await axios.post(
                      `${env.API_URL}/post/teacher/${teacherId}/image`,
                      formData,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    );
                  } catch {}
                }
                resolve({});
              })
            )
        )
      );
      await Promise.all(promises);
    }
    navigate("/proximas-clases");
  };

  const { open, description, title, openModal, acceptButton, onClose } =
    useModal({});

  const handleRemoveTeacher = (cb: () => void) => () => {
    openModal({
      title: "Eliminar profesor",
      description: "¿Seguro que desea eliminar el profesor de esta clase?",
      acceptButtonTitle: "Eliminar",
      cb,
    });
  };

  const handleRemoveModule = (cb: () => void) => () => {
    openModal({
      title: "Eliminar clase",
      description: "¿Seguro desea eliminar esta clase del curso?",
      acceptButtonTitle: "Eliminar",
      cb,
    });
  };

  const handleGoBack = (cb: () => void) => () => {
    openModal({
      title: "Salir sin guardar",
      description:
        "Los cambios realizados se perderán ¿Deseas salir de todas formas?",
      acceptButtonTitle: "Salir",
      cb,
    });
  };

  const handleSave = (cb: () => void) => () => {
    openModal({
      title: "Guardar Curso",
      description:
        "El curso se guardará como borrador en la biblioteca privada \n¿Deseas guardar sin publicar?",
      acceptButtonTitle: "Guardar",
      cb,
    });
  };

  const handlePublish = (cb: () => void) => () => {
    openModal({
      title: "Publicar Curso",
      description:
        "El curso se agregará a la biblioteca pública de Academia Movistar \n¿Deseas publicar ahora?",
      acceptButtonTitle: "Publicar",
      cb,
    });
  };

  const handleDeleteImage = () => {
    openModal({
      title: "Eliminar Portada",
      description: "¿Seguro que desea eliminar la portada del curso?",
      acceptButtonTitle: "Eliminar",
      cb: () => {
        setCourseImage(undefined);
        setCourseDetails({ ...courseDetails, imageUrl: "" });
      },
    });
  };

  const handleSetTeacherImageFactory =
    (
      moduleIndex: number,
      teacherIndex: number
    ): React.ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      if (e.target.files && e.target.files[0]) {
        setTeacherImages({
          ...teacherImages,
          [moduleIndex]: {
            ...teacherImages[moduleIndex],
            [teacherIndex]: e.target.files[0],
          },
        });
      }
    };

  const deleteTeacherImage = (moduleIndex: number, teacherIndex: number) => {
    // what a mess
    if (teacherImages && teacherImages[moduleIndex]) {
      let newTeacherImages = {
        ...teacherImages,
        [moduleIndex]: Object.fromEntries(
          Object.entries(teacherImages[moduleIndex] || {}).filter(
            ([key, value]) => parseInt(key) !== teacherIndex
          ) || {}
        ),
      };
      setTeacherImages(newTeacherImages);
    }
    let newModules = [...modules];
    newModules[moduleIndex].teachers[teacherIndex].profileImageUrl = "";
    setModules(newModules);
  };

  const handleDeleteTeacherImage = (
    moduleIndex: number,
    teacherIndex: number
  ) => {
    openModal({
      title: "Eliminar foto de perfil",
      description: "¿Seguro que desea eliminar la foto de perfil del profesor?",
      acceptButtonTitle: "Eliminar",
      cb: () => deleteTeacherImage(moduleIndex, teacherIndex),
    });
  };

  /* const getTeacherImage = (moduleIndex: number, teacherIndex: number) =>
    _.get(
      courseDetails,
      `courseModules.${moduleIndex}.teachers.${teacherIndex}`,
      undefined
    ); */

  const getTeacherPendingImage = (moduleIndex: number, teacherIndex: number) =>
    _.get(teacherImages, `${moduleIndex}.${teacherIndex}`, undefined) as {name: string} | undefined;

  /* const teacherHasImage = (moduleIndex: number, teacherIndex: number) =>
    getTeacherPendingImage(moduleIndex, teacherIndex) &&
    getTeacherImage(moduleIndex, teacherIndex); */

  const [scheduleToAdd, setScheduleToAdd] = useState("");
  //const [currentFrame, setCurrentFrame] = useState<string>("");

  const addTimeFrame = () => {
    // Agrega el horario actual al array de timeFrames
    onChangeCourseField("schedule", [
      ...courseDetails?.schedule,
      scheduleToAdd,
    ]);
    setScheduleToAdd("");
  };

  return (
    <>
      <Modal
        acceptButton={acceptButton}
        open={open}
        title={title}
        description={description}
        onClose={onClose}
      />
      <FormNavbar
        title={editMode ? "Editar Próximo Curso" : "Nuevo Próximo Curso"}
        onClickGoBack={handleGoBack(() => navigate("/proximas-clases"))}
        onClickSave={handleSave(onClickSave)}
        onClickPublish={handlePublish(onClickSave)}
      />
      <PageGrid>
        <LateralBar>
          <FormAppBarButton
            selected={selectedForm === "info"}
            onClick={() => setSelectedForm("info")}
          >
            Información general
          </FormAppBarButton>
          <FormAppBarButton
            selected={selectedForm === "modules"}
            onClick={() => setSelectedForm("modules")}
          >
            Clases del curso
          </FormAppBarButton>
        </LateralBar>
        <FormFlex
          style={{ display: selectedForm !== "info" ? "none" : "flex" }}
        >
          <InputWrapper>
            <InputLabel>Título del curso</InputLabel>
            <Input
              onChange={(e) => onChangeCourseField("title", e.target.value)}
              placeholder="Ingresa el título del curso"
              value={courseDetails?.title}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Webinario de WebinarJam</InputLabel>
            <Select
              styles={{
                container: (provided) => ({
                  ...provided,
                  zIndex: 2,
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: 0,
                }),
              }}
              placeholder={
                webinarsLoading ? "Cargando..." : "Selecciona un Webinario..."
              }
              options={webinarsSelectOptions}
              value={selectedWebinar}
              isLoading={webinarsLoading}
              //@ts-ignore
              onChange={(newVal: { label: string; value: number }) =>
                onChangeCourseField("webinarJamId", newVal?.value)
              }
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Imagen del curso</InputLabel>
            {!courseDetails?.imageUrl && !courseImage?.size ? (
              <FileUploadLabel style={{ position: "relative" }}>
                <HiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    e.target.files &&
                      e.target.files[0] &&
                      setCourseImage(e.target.files[0]);
                  }}
                />
                Cargar imagen
              </FileUploadLabel>
            ) : (
              <FileDisplay onClickDelete={handleDeleteImage}>
                {courseImage?.name || courseDetails?.imageUrl}
              </FileDisplay>
            )}
          </InputWrapper>
          <InputWrapper>
            <InputLabel style={{ borderTop: "1px solid #AAAAAA" }}>
              Descripción general
            </InputLabel>
            <TextArea
              onChange={(e) =>
                onChangeCourseField("description", e.target.value)
              }
              placeholder="Escribe una descripción"
              value={courseDetails?.description}
            />
          </InputWrapper>

          <InputWrapper>
            <InputLabel>Horario</InputLabel>
            <Input
              onChange={(e) => {
                setScheduleToAdd(e.target.value);
              }}
              placeholder="Describe el horario y país"
            />

            <div
              style={{
                display: "flex",
              }}
            >
              <p style={{ fontSize: "30px", color: "#019af1" }}>+</p>
              <PlusButton onClick={addTimeFrame} type="button" dark>
                Agregar
              </PlusButton>
            </div>

            {courseDetails?.schedule.map((schedule, index) => (
              <div
                key={index}
                style={{
                  border: "1px solid #AAAAAA",
                  width: "200px",
                  height: "30px",
                  margin: "8px",
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <p>{schedule}</p>
              </div>
            ))}
          </InputWrapper>

          <InputWrapper>
            <InputLabel>Objetivo</InputLabel>
            <Input
              onChange={(e) => onChangeCourseField("objective", e.target.value)}
              placeholder="Escribe el objetivo del curso"
              value={courseDetails?.objective}
            />
          </InputWrapper>

          <div>
            <InputWrapper>
              <InputLabel>Certificado</InputLabel>
              <Input
                onChange={(e) => {
                  if (!courseDetails?.certified) {
                    onChangeCourseField("certificate", e.target.value);
                  }
                }}
                placeholder="Describe la certificación del curso"
                value={courseDetails?.certificate}
                disabled={courseDetails?.certified}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>No certificado</InputLabel>
              <Checkbox
                type="checkbox"
                onChange={(e) => {
                  const value = e.target.checked;
                  onChangeCourseField("certified", value);
                }}
                defaultChecked={!!courseDetails?.certified}
                disabled={!!courseDetails?.certificate}
              />
            </InputWrapper>
          </div>

          <InputWrapper>
            <InputLabel>Modalidad</InputLabel>
            <Input
              onChange={(e) => onChangeCourseField("mode", e.target.value)}
              placeholder="Escribe la modalidad de del curso "
              value={courseDetails?.mode}
            />
          </InputWrapper>

          <InputWrapper>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                type="checkbox"
                defaultChecked={courseDetails?.inscription}
                onChange={(e) => {
                  const value = e.target.checked;
                  onChangeCourseField("inscription", value);
                }}
              />
              <InputLabel style={{ margin: "6px" }}>
                Formulario de inscripción
              </InputLabel>
            </div>
          </InputWrapper>
        </FormFlex>
        <div style={{ display: selectedForm !== "modules" ? "none" : "", width: "50%"}}>
          {modules &&
            modules.map(
              ({ name, description, teachers, moduleDate }, moduleIndex) => (
                <FormFlex key={moduleIndex} style={{width: "100%"}}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                    key={moduleIndex}
                  >
                    <InputWrapper style={{ flexGrow: 1 }}>
                      <InputLabel>Título de la clase</InputLabel>
                      <Input
                        onChange={(e) =>
                          onChangeModuleField(
                            "name",
                            moduleIndex,
                            e.target.value
                          )
                        }
                        placeholder="Ingresa el título del curso"
                        value={name}
                      />
                    </InputWrapper>
                    {modules.length > 1 && (
                      <ActionButton
                        onClick={handleRemoveModule(() =>
                          removeModule(moduleIndex)
                        )}
                        iconSrc={deleteSrc}
                      />
                    )}
                  </div>

                  <div>
                    <InputLabel>Fecha</InputLabel>
                    <CustomDatePicker
                      selected={
                        moduleDate
                          ? moment(moduleDate, "YYYY-MM-DD").toDate()
                          : new Date()
                      }
                      onChange={(date) => {
                        if (date != null) {
                          const formattedDate = moment(date.toString()).format(
                            "YYYY-MM-DD"
                          );
                          onChangeModuleField(
                            "moduleDate",
                            moduleIndex,
                            formattedDate
                          );
                        }
                      }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>

                  <InputWrapper>
                    <InputLabel>Descripción de la clase</InputLabel>
                    <TextArea
                      onChange={(e) =>
                        onChangeModuleField(
                          "description",
                          moduleIndex,
                          e.target.value
                        )
                      }
                      placeholder="Escribe una descripción"
                      value={description}
                    />
                  </InputWrapper>
                  {teachers.map(
                    (
                      { name, title, linkedinUrl, profileImageUrl },
                      teacherIndex
                    ) => (
                      <div
                        style={{
                          border: "1px solid #AAAAAA",
                          width: "auto",
                          height: "auto",
                          padding: "20px",
                        }}
                        key={teacherIndex}
                      >
                        <InputWrapper key={`${moduleIndex}-${teacherIndex}`}>
                          <InputLabel>Dictado por</InputLabel>
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              alignItems: "center",
                            }}
                          >
                            <InputWrapper style={{ flexGrow: "18" }}>
                              <Input
                                style={{ padding: "8px" }}
                                onChange={(e) =>
                                  onChangeTeacherField(
                                    "name",
                                    moduleIndex,
                                    teacherIndex,
                                    e.target.value
                                  )
                                }
                                placeholder="Ingresa nombre del profesor"
                                value={name}
                              />

                              <Input
                                style={{ padding: "8px" }}
                                onChange={(e) =>
                                  onChangeTeacherField(
                                    "linkedinUrl",
                                    moduleIndex,
                                    teacherIndex,
                                    e.target.value
                                  )
                                }
                                placeholder="Link a su Linkedin"
                                value={linkedinUrl}
                              />
                            </InputWrapper>

                            <ActionButton
                              style={{}}
                              onClick={handleRemoveTeacher(() =>
                                removeTeacher(moduleIndex, teacherIndex)
                              )}
                              iconSrc={deleteSrc}
                            />
                          </div>
                          {!(
                            profileImageUrl ||
                            getTeacherPendingImage(moduleIndex, teacherIndex)
                          ) ? (
                            <div
                              style={{
                                gap: "10px",
                                width: "220px",
                                height: "48px",
                                display: "flex",
                                marginTop: "15px",
                                border: "1px solid#019af1",
                                cursor: "pointer",
                              }}
                            >
                              <FileUploadLabel
                                style={{
                                  position: "relative",
                                  zIndex: 1,
                                  padding: "8px",
                                  textDecoration: "none",
                                  color: "#019af1",
                                  fontSize: "18px",
                                }}
                              >
                                <HiddenInput
                                  style={{
                                    padding: "8px",
                                  }}
                                  type="file"
                                  accept="image/*"
                                  onChange={handleSetTeacherImageFactory(
                                    moduleIndex,
                                    teacherIndex
                                  )}
                                />
                                Cargar imagen de avatar ⇫
                              </FileUploadLabel>
                            </div>
                          ) : (
                            <FileDisplay
                              onClickDelete={() =>
                                handleDeleteTeacherImage(
                                  moduleIndex,
                                  teacherIndex
                                )
                              }
                            >
                              {getTeacherPendingImage(moduleIndex, teacherIndex)
                                ?.name || profileImageUrl}
                            </FileDisplay>
                          )}
                        </InputWrapper>
                      </div>
                    )
                  )}

                  <div
                    style={{
                      display: "flex",
                      marginTop: "-18px",
                    }}
                  >
                    <p style={{ fontSize: "30px", color: "#019af1" }}>+</p>
                    <PlusButton
                      filled
                      style={{
                        fontSize: "18px",
                      }}
                      type="button"
                      onClick={() => addTeacher(moduleIndex)}
                    >
                      Agregar profesor
                    </PlusButton>
                  </div>
                </FormFlex>
              )
            )}
          <div style={{ borderTop: "1px solid #AAAAAA" }}>
            <RoundedButton
              filled
              onClick={addModule}
              style={{ padding: "8px", marginTop: "15px" }}
            >
              Agregar módulo
            </RoundedButton>
          </div>
        </div>
      </PageGrid>
    </>
  );
};
// function setStartDate(arg0: Date): void {
//   throw new Error("Function not implemented.");
// }
