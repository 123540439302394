import styled from "styled-components";

export const Input = styled.input`
  background-image: none;
  outline: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #aaaaaa;
  font-size: 1rem;
  padding-bottom: 5px;
  padding-top: 5px;
  font-family: telefonicaWeb;
`;

export const SearchInput = styled(Input)`
  height: 30px;
  padding-left: 5px;
  border: 1px solid #707070;
  margin: 0px;
  margin-top: 0.5px;
`;

export const InputLabel = styled.label`
  font-family: telefonicaBold;
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 5px;
`;

export const TextArea = styled.textarea`
  background-image: none;
  outline: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  border: 1px solid #aaaaaa;
  font-size: 1rem;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  font-family: telefonicaWeb;
  margin-bottom: 20px;
  margin-top: 3px;
  height: 200px;
  resize: none;
`;

export const HomeTextArea = styled.textarea`
  background-image: none;
  outline: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #aaaaaa;
  font-size: 1rem;
  padding-bottom: 5px;
  padding-top: 5px;
  font-family: telefonicaWeb;
  min-height: 90px;
`;

// Estilos para el componente de calendario
export const CalendarContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
`;

export const CalendarMonth = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
`;

export const CalendarHeader = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

export const CalendarBody = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const CalendarDay = styled.div`
  text-align: center;
  padding: 5px;
`;
