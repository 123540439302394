import axios from "../../axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormFlex,
  FormAppBarButton,
  FormNavbar,
  Input,
  InputLabel,
  InputWrapper,
  LateralBar,
  PageGrid,
  Select,
  SelectWrapper,
  TextArea,
  useModal,
  Modal,
  RoundedButton,
  DeleteButton,
  Chip,
} from "../../components";
import { env } from "../../config";
import { AuthContext } from "../../context";
import { useGetCategories, useGetRecordedCourseTags } from "../../services";
import { useGetRecordedCourseDetails } from "../../services/useGetRecordedCourseDetails";
import _ from "lodash";
import { useDeepCompareEffect } from "react-use";
import toast from "react-hot-toast";

const getInitialRecordedCourseDetails = (): Partial<RecordedCourse> => ({
  title: "",
  description: "",
  youtubeUrl: "",
});

export const RecordedCourseFormPage: React.FC<{ editMode?: boolean }> = ({
  editMode,
}) => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [recordedCourseDetails, setRecordedCourseDetails] = useState<
    Partial<RecordedCourse>
  >(getInitialRecordedCourseDetails());

  const { recordedCourseId } = useParams();

  const { recordedCourse } = useGetRecordedCourseDetails(
    parseInt(recordedCourseId || "1")
  );

  useEffect(() => {
    if (recordedCourse && editMode) {
      setRecordedCourseDetails(recordedCourse);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedCourse]);

  const onClickSave = async () => {
    setIsSaveLoading(true);
    try {
      let recordedCourseDetailsToSave = {
        ...Object.fromEntries(
          Object.entries(recordedCourseDetails).filter(
            ([key, val]) =>
              key !== "category" &&
              key !== "_links" &&
              key !== "recordedCourseTags"
          )
        ),
      };
      recordedCourseDetailsToSave.subjects = JSON.stringify(
        recordedCourseDetailsToSave.subjects || ""
      );
      const response = await axios[
        recordedCourseId ? "put" : "post"
      ]<RecordedCourse>(
        `${env.API_URL}/recordedCourses${
          recordedCourseId ? `/${recordedCourseId}` : ""
        }`,
        recordedCourseDetailsToSave,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const tagLinks: string[] | undefined =
        recordedCourseDetails?.recordedCourseTags?.map(
          ({ id }) => `${env.API_URL}/recordedCourseTags/${id}`
        );
      const tagLinksUriList = tagLinks ? tagLinks.join("\n") : "";
      await axios.put(
        `${env.API_URL}/recordedCourses/${response.data.id}/recordedCourseTags`,
        tagLinksUriList,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "text/uri-list",
          },
        }
      );
      await axios.put(
        `${env.API_URL}/recordedCourses/${response.data.id}/category`,
        `${env.API_URL}/categories/${recordedCourseDetails.categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "text/uri-list",
          },
        }
      );
      toast.success(
        editMode
          ? "¡Curso Actualizado con Éxito!"
          : "¡Curso Publicado con Éxito!",
        {
          position: "bottom-center",
        }
      );
      navigate("/");
    } catch (err) {
      toast.error(JSON.stringify(err));
    } finally {
      setIsSaveLoading(false);
    }
  };

  const { categories } = useGetCategories();

  const { recordedCourseTags } = useGetRecordedCourseTags();

  useEffect(() => {
    if (!recordedCourseDetails.category)
      setRecordedCourseDetails({
        ...recordedCourseDetails,
        ...(categories && categories.length
          ? { categoryId: categories[0].id }
          : {}),
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const onChangeRecordedCourseField = (
    fieldName: string,
    newValue: string | number
  ) => {
    setRecordedCourseDetails({
      ...recordedCourseDetails,
      [fieldName]: newValue,
    });
  };

  const { open, description, title, openModal, acceptButton, onClose } =
    useModal({});

  const handleGoBack = (cb: () => void) => () => {
    openModal({
      title: "Salir sin guardar",
      description:
        "Los cambios realizados se perderán ¿Deseas salir de todas formas?",
      acceptButtonTitle: "Salir",
      cb,
    });
  };

  const handleSave = (cb: () => void) => () => {
    openModal({
      title: "Guardar Curso",
      description:
        "El curso se guardará como borrador en la biblioteca privada \n¿Deseas guardar sin publicar?",
      acceptButtonTitle: "Guardar",
      cb,
    });
  };

  const handlePublish = (cb: () => void) => () => {
    openModal({
      title: "Publicar Curso",
      description:
        "El curso se agregará a la biblioteca pública de Academia Movistar \n¿Deseas publicar ahora?",
      acceptButtonTitle: "Publicar",
      cb,
    });
  };

  const addSubject = () => {
    setRecordedCourseDetails({
      ...recordedCourseDetails,
      subjects: [...(recordedCourseDetails?.subjects || []), ""],
    });
  };

  const deleteSubject = (index: number) => {
    let newSubjects = [...(recordedCourseDetails?.subjects || [])];
    newSubjects.splice(index, 1);
    setRecordedCourseDetails({
      ...recordedCourseDetails,
      subjects: newSubjects,
    });
  };

  const onChangeSubject = (index: number, newValue: string) => {
    let newSubjects = [...(recordedCourseDetails?.subjects || [])];
    newSubjects[index] = newValue;
    setRecordedCourseDetails({
      ...recordedCourseDetails,
      subjects: newSubjects,
    });
  };

  const handleDeleteSubject = (cb: () => void) => () => {
    openModal({
      title: "Eliminar Tema",
      description: "¿Seguro desea eliminar este tema del curso?",
      acceptButtonTitle: "Eliminar",
      cb,
    });
  };

  const getTagsAvailable = () => {
    let cache: Record<number, string> = [];

    recordedCourseDetails?.recordedCourseTags?.forEach((tag) => {
      cache[tag.id] = tag.name;
    });

    return recordedCourseTags?.filter((tag) => !Boolean(cache[tag.id]));
  };

  const tagsAvailable = getTagsAvailable();

  const [selectedTag, setSelectedTag] = useState<number>();

  useDeepCompareEffect(() => {
    const selectedTagInAvailable = tagsAvailable?.find(
      (tag) => tag.id === selectedTag
    );
    if (tagsAvailable && !selectedTagInAvailable?.id) {
      setSelectedTag(_.get(tagsAvailable, "0.id", 0));
    }
  }, [recordedCourseTags, recordedCourse, tagsAvailable?.length]);

  const addTag = (id: number) => {
    const tagToAdd = tagsAvailable?.find((tag) => tag.id === id);
    if (tagToAdd) {
      setRecordedCourseDetails({
        ...recordedCourseDetails,
        recordedCourseTags: [
          ...(recordedCourseDetails?.recordedCourseTags || []),
          tagToAdd,
        ],
      });
    }
  };

  const deleteTag = (id: number) => {
    const tagToRemove = recordedCourseDetails?.recordedCourseTags?.find(
      (tag) => tag.id === id
    );
    if (tagToRemove) {
      let newTags = [...(recordedCourseDetails?.recordedCourseTags || [])];
      newTags.splice(
        recordedCourseDetails!.recordedCourseTags!.indexOf(tagToRemove),
        1
      );
      setRecordedCourseDetails({
        ...recordedCourseDetails,
        recordedCourseTags: newTags,
      });
      setSelectedTag(_.get(tagsAvailable, "0.id", 0));
    }
  };

  return (
    <>
      <Modal
        acceptButton={acceptButton}
        open={open}
        title={title}
        description={description}
        onClose={onClose}
      />
      <FormNavbar
        title={editMode ? "Editar Curso" : "Nuevo Curso"}
        onClickGoBack={handleGoBack(() => navigate("/"))}
        onClickSave={handleSave(onClickSave)}
        onClickPublish={handlePublish(onClickSave)}
        disabled={isSaveLoading}
      />
      <PageGrid>
        <LateralBar>
          <FormAppBarButton selected>Información general</FormAppBarButton>
        </LateralBar>
        <form style={{width: "100%"}}>
          <FormFlex>
            <InputWrapper>
              <InputLabel>Título del curso</InputLabel>
              <Input
                onChange={(e) =>
                  onChangeRecordedCourseField("title", e.target.value)
                }
                placeholder="Ingresa el título del curso"
                value={recordedCourseDetails.title}
              />
            </InputWrapper>
            <SelectWrapper>
              <InputLabel>Categoría</InputLabel>
              <Select
                onChange={(e) =>
                  onChangeRecordedCourseField(
                    "categoryId",
                    parseInt(e.target.value)
                  )
                }
                name="categories"
                value={recordedCourseDetails.categoryId}
              >
                {categories?.map(({ name, id }, index) => (
                  <option key={index} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </SelectWrapper>
            <InputWrapper>
              <InputLabel>Descripción general</InputLabel>
              <TextArea
                onChange={(e) =>
                  onChangeRecordedCourseField("description", e.target.value)
                }
                placeholder="Escribe una descripción"
                value={recordedCourseDetails.description}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>Link al video (youtube)</InputLabel>
              <Input
                onChange={(e) =>
                  onChangeRecordedCourseField("youtubeUrl", e.target.value)
                }
                placeholder="Pega o escribe link al video"
                value={recordedCourseDetails.youtubeUrl}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>Link al contenido (descargable)</InputLabel>
              <Input
                onChange={(e) =>
                  onChangeRecordedCourseField("contentUrl", e.target.value)
                }
                placeholder="Pega o escribe link al contenido"
                value={recordedCourseDetails.contentUrl}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>Temas a tratar</InputLabel>
              {recordedCourseDetails.subjects &&
                recordedCourseDetails.subjects?.map((subject, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Input
                      onChange={(e) => onChangeSubject(index, e.target.value)}
                      value={subject}
                      style={{ flexGrow: 1 }}
                    />
                    <DeleteButton
                      onClick={handleDeleteSubject(() => deleteSubject(index))}
                      black
                    />
                  </div>
                ))}
              <div>
                <RoundedButton
                  filled
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    marginTop: "10px",
                  }}
                  type="button"
                  onClick={() => addSubject()}
                >
                  Agregar Tema
                </RoundedButton>
              </div>
            </InputWrapper>
            <InputWrapper>
              <InputLabel>Tags</InputLabel>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <Select
                  onChange={(e) => setSelectedTag(parseInt(e.target.value))}
                  value={selectedTag}
                >
                  {tagsAvailable?.length === 0 && (
                    <option key={1} value={0}>
                      ---
                    </option>
                  )}
                  {tagsAvailable &&
                    tagsAvailable?.map(({ name, id }, index) => (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    ))}
                </Select>
                <RoundedButton
                  filled
                  style={{
                    display: "block",
                    fontSize: "20px",
                    lineHeight: "20px",
                    height: "30px",
                    width: "30px",
                    padding: "0px",
                    fontWeight: "bold",
                    fontFamily: "telefonicaBold",
                  }}
                  type="button"
                  onClick={() => selectedTag && addTag(selectedTag)}
                  disabled={tagsAvailable?.length === 0}
                >
                  &#65291;
                </RoundedButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexGrow: 1,
                  gap: "10px",
                  alignItems: "left",
                  width: "100%",
                }}
              >
                {recordedCourseDetails &&
                  recordedCourseDetails.recordedCourseTags &&
                  recordedCourseDetails.recordedCourseTags?.map(
                    (recordedCourseTag, index) => (
                      <Chip
                        dark
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingRight: "10px",
                          gap: "20px",
                          flexWrap: "nowrap",
                          lineHeight: "1rem",
                          minWidth: "160px",
                        }}
                      >
                        <div>{recordedCourseTag.name}</div>
                        <DeleteButton
                          white
                          onClick={() => deleteTag(recordedCourseTag.id)}
                        />
                      </Chip>
                    )
                  )}
              </div>
            </InputWrapper>
          </FormFlex>
        </form>
      </PageGrid>
    </>
  );
};
