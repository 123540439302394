import { useContext } from "react";
import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";
import { useWindowSize } from "./helpers";
import { AuthContext } from "./context";
import { CourseFormPage, LoginPage, RecordedCoursesPage, HomeFormPage } from "./pages";
import { CoursesPage } from "./pages/courses";
import { RecordedCourseFormPage } from "./pages/recordedCourseForm";
import { RestrictedToPc } from "./pages/restrictedToPc";

const App: React.FC = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { width } = useWindowSize();
  const isSmall = width < 850;
  return (
    <>
      <div style={{ display: isSmall ? "initial" : "none" }}>
        <RestrictedToPc />
      </div>
      <div style={{ display: isSmall ? "none" : "initial" }}>
        {!isAuthenticated ? (
          <LoginPage />
        ) : (
          <>
            <Routes>
              <Route path="/" element={<RecordedCoursesPage />} />
              <Route path="/proximas-clases" element={<CoursesPage />} />
              <Route path="/curso-proximo" element={<CourseFormPage />} />
              <Route path="/home" element={<HomeFormPage/>} />
              <Route
                path="/curso-proximo/:courseId"
                element={<CourseFormPage editMode />}
              />
              <Route
                path="/curso-grabado"
                element={<RecordedCourseFormPage />}
              />
              <Route
                path="/curso-grabado/:recordedCourseId"
                element={<RecordedCourseFormPage editMode />}
              />
            </Routes>
          </>
        )}
      </div>
      <Toaster />
    </>
  );
};

export default App;
