import { useContext } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
  Input,
  InputLabel,
  InputWrapper,
  RoundedButton,
} from "../../components";
import { AuthContext } from "../../context";
import logoSrc from "../../assets/images/home-title.svg";
import toast from "react-hot-toast";

const Logo = styled.div`
  height: 250px;
  width: 250px;
  background: url(${logoSrc}) no-repeat center center;
  background-size: contain;
`;

export const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    //formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const { authenticate } = useContext(AuthContext);

  const onSubmit = handleSubmit(async ({ username, password }) => {
    toast
      .promise(
        authenticate(username, password),
        {
          loading: "Iniciando Sesión...",
          success: <b>Bienvenido!</b>,
          error: (err) => {
            setError("username", {});
            setError("password", {});
            return (
              <b>
                Ha ocurrido un error, revisa tus credenciales o contacta al
                administrador.
              </b>
            );
          },
        },
        {
          position: "bottom-center",
        }
      )
  });

  return (
    <div
      style={{
        background: "#0B2739",
        width: "100vw",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <div style={{ padding: 3, display: "flex", alignItems: "center" }}>
          <form onSubmit={onSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Logo />
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "1.1rem",
                  marginTop: "-20px",
                  fontWeight: 600,
                  marginBottom: "50px",
                  textTransform: "uppercase",
                  fontFamily: "telefonicaBold",
                  color: "white",
                }}
              >
                Iniciar Sesión
              </h1>
              <InputWrapper style={{ width: "100%", marginBottom: "30px" }}>
                <InputLabel style={{ color: "white" }}>Usuario</InputLabel>
                <Input
                  placeholder="Ingresa nombre de usuario"
                  style={{ color: "white" }}
                  {...register("username", { required: true })}
                  // error={Boolean(errors.username)}
                />
              </InputWrapper>
              <InputWrapper style={{ width: "100%", marginBottom: "30px" }}>
                <InputLabel style={{ color: "white" }}>Contraseña</InputLabel>
                <Input
                  placeholder="Ingresa contraseña"
                  style={{ color: "white" }}
                  type="password"
                  {...register("password", { required: true })}
                  // error={Boolean(errors.password)}
                />
              </InputWrapper>
            </div>
            <RoundedButton
              style={{ marginTop: "20px" }}
              wide
              white
              type="submit"
            >
              Ingresar
            </RoundedButton>
          </form>
        </div>
      </div>
    </div>
  );
};
