import axios from "../../axios";
import { env } from "../../config";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  LibraryNavbar,
  Modal,
  PageContainer,
  RoundedButton,
  SearchInput,
  SearchWrapper,
  SelectLabel,
  SelectWrapper,
  useModal,
  WebinarCard,
  WebinarsGrid,
} from "../../components";
import { AuthContext } from "../../context";
import { useGetCourses } from "../../services";
import { HeroBannerForm } from "../heroBannerForm";
import styled from "styled-components";

export const CoursesPage: React.FC = () => {
  let navigate = useNavigate();

  const { token } = useContext(AuthContext);

  const { courses, refreshCourses, setSearch } = useGetCourses();

  const { open, description, title, openModal, acceptButton, onClose } =
    useModal({});

  const deleteCourse = async (id: number) => {
    await axios.delete(`${env.API_URL}/courses/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    refreshCourses();
  };

  const handleRemoveCourse = (cb: () => void) => () => {
    openModal({
      title: "Eliminar curso",
      description: "¿Seguro que desea eliminar el curso?",
      acceptButtonTitle: "Eliminar",
      cb,
    });
  };

  const downloadCourseUsers = async (
    title: string,
    courseId: number | string
  ) => {
      var hiddenElement = document.createElement("a");
      hiddenElement.target = "_blank"
      hiddenElement.href = `${env.API_URL}/students?courseId=${courseId}`;
      hiddenElement.click();
  };

  const Disclaimer = styled.p`
  color: #aaaaaa;
  margin-bottom: 20px;
`

  return (
    <>
      <Modal
        acceptButton={acceptButton}
        open={open}
        title={title}
        description={description}
        onClose={onClose}
      />
      <LibraryNavbar />
      <PageContainer contentStyle={{ padding: "60px" }}>
        <h2 style={{textAlign: "center", marginBottom: "20px"}}>
          Hero Banner
        </h2>
        <Disclaimer><small>* Tenga en cuenta que al elegir una única imagen, esta se mostrará como un Hero banner. En caso de seleccionar varias imágenes, se presentarán en un carrusel.</small></Disclaimer>
        <HeroBannerForm path={"cursos"}/>
        <h2 style={{textAlign: "center", marginBottom: "20px"}}>
          Lista de Próximas Clases
        </h2>
        <SearchWrapper>
          <SelectWrapper style={{ flexGrow: 1 }}>
            <SelectLabel>buscar</SelectLabel>
            <SearchInput
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Ingresa lo que quieras buscar"
            />
          </SelectWrapper>
          <div style={{ flexGrow: 1 }} />
          <RoundedButton filled onClick={() => navigate("/curso-proximo")}>
            Crear nuevo curso
          </RoundedButton>
        </SearchWrapper>

        <WebinarsGrid>
          {courses?.map(({ title, description, imageUrl, id }, index) => (
            <WebinarCard
              title={title}
              description={description || ""}
              thumbnail={imageUrl}
              onClickDelete={handleRemoveCourse(() => deleteCourse(id!))}
              onClickEdit={() => navigate(`/curso-proximo/${id}`)}
              onClickDownloadUsers={() =>
                id ? downloadCourseUsers(title, id) : {}
              }
              key={index}
            />
          ))}
        </WebinarsGrid>
      </PageContainer>
    </>
  );
};
