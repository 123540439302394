import styled from "styled-components";

export const WebinarsGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  grid-column-gap: 12px;
  grid-row-gap: 25px;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 25px;
  }
`;