import React, { useContext, useEffect, useState } from "react";
//import imageCompression from "browser-image-compression";
import { useGetCarousel } from "../../services/useGetCarousels";
import deleteSrc from "../../assets/images/delete.svg";
import { AuthContext } from "../../context";
import { env } from "../../config";
import axios from "axios";
import _ from "lodash";
import styled from "styled-components";
import editSrc from "../../assets/images/edit.svg";

import {
  ActionButton,
  BannerCard,
  DeleteButton,
  FileUploadLabel,
  HomeFormFlex,
  HiddenInput,
  Input,
  InputLabel,
  InputWrapper,
  Modal,
  HomePageGrid,
  useModal,
  RoundedButton,
} from "../../components";

const FileDisplay: React.FC<{
  onClickDelete: () => void;
  children: React.ReactNode;
}> = ({ onClickDelete, children }) => (
  <div
    style={{
      gap: "10px",
      width: "auto",
      height: "48px",
      display: "flex",
      marginTop: "15px",
      border: "none",
      alignItems: "center",
    }}
  >
    <FileUploadLabel
      style={{ cursor: "initial", textDecoration: "none", color: "#019af1" }}
    >
      {children}
    </FileUploadLabel>
    <DeleteButton onClick={onClickDelete} />
  </div>
);

interface EditButtonProps {
  iconSrc?: string;
}

export const EditButton = styled.button<EditButtonProps>`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: none;
  background-color: #019af1;
  z-index: 1;
  cursor: pointer;
  ${({ iconSrc }) => iconSrc ?
  `
    background-image: url(${iconSrc});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
  `
  : ``}
`;

interface CarouselHeaderProps {
  path: string;
}

export const HeroBannerForm: React.FC<CarouselHeaderProps> = ({ path }) => {

  //initial values
  const getInitialSlide = (): Slide => ({
    title: "",
    slideDesktopImageUrl: "",
    slideMobileImageUrl: ""
  });

  const getInitialCarousel = (): Carousel => ({
    section: path,
    slides: [getInitialSlide()]
  });

  interface SlideImages {
    [key: number]: {
      slideDesktopImageFile?: File;
      slideMobileImageFile?: File;
    };
  }

  const { token } = useContext(AuthContext);
  const { open, description, title, openModal, acceptButton, onClose } = useModal({});

  const { carouselData, setforceRefresh } = useGetCarousel(path);
  const [carousel, setCarousel] = useState<Carousel>(getInitialCarousel());
  const [slideImages, setSlideImages] = useState<SlideImages>({});
  const [disabledFormElements, setDisabledFormElements] = useState(true);

  useEffect(() => {
    if (carouselData) {
      setCarousel(carouselData);
    }
  }, [carouselData]);

  const onChangeTitleField = (
    fieldName: string,
    slideIndex: number,
    newValue: string
  ) => {
    let newSlide = {
      ...carousel.slides[slideIndex],
      [fieldName]: newValue,
    };
    let newCarousel = { ...carousel };
    newCarousel.slides[slideIndex] = newSlide;
    setCarousel(newCarousel);
  };

  const removeSlide = (slideIndex: number) => {
    deleteSlideImage(slideIndex, 'desktop');
    deleteSlideImage(slideIndex, 'mobile');
  
    const newSlides = carousel.slides.filter((_, idx) => idx !== slideIndex);
    const newSlideImages = { ...slideImages };
    delete newSlideImages[slideIndex];
  
    setCarousel({ ...carousel, slides: newSlides });
    setSlideImages(newSlideImages);
  };
  

  const addSlide = () => {
    let newSlides = {...carousel};
    newSlides.slides.push(getInitialSlide());
    setCarousel(newSlides);
  };

  const handleSetSlideImageFactory = (slideIndex: number, imageType: 'desktop' | 'mobile'): React.ChangeEventHandler<HTMLInputElement> =>
  (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];

      setSlideImages((prevSlideImages) => ({
        ...prevSlideImages,
        [slideIndex]: {
          ...prevSlideImages[slideIndex],
          [imageType === 'desktop' ? 'slideDesktopImageFile' : 'slideMobileImageFile']: selectedFile,
        },
      }));
    }
  };

  const deleteSlideImage = (slideIndex: number, imageType: 'desktop' | 'mobile') => {
    if (slideImages && slideImages[slideIndex]) {
      const newSlideImages = { ...slideImages };
      delete newSlideImages[slideIndex][imageType === 'desktop' ? 'slideDesktopImageFile' : 'slideMobileImageFile'];
      setSlideImages(newSlideImages);
    }
  };

  const handleRemoveSlide = (cb: () => void) => () => {
    openModal({
      title: "Eliminar Slide",
      description: "¿Seguro que desea eliminar este slide del carrousel?",
      acceptButtonTitle: "Eliminar",
      cb,
    });
  };

  const handleDeleteSlideImage = ( slideIndex: number, imageType: 'desktop' | 'mobile') => {
    openModal({
      title: "Eliminar imagen",
      description: "¿Seguro que desea eliminar esta imagen?",
      acceptButtonTitle: "Eliminar",
      cb: () => deleteSlideImage(slideIndex, imageType)
    });
  };

  const getSlidePendingImage = (slideIndex: number, imageType: 'desktop' | 'mobile'): string | undefined => {
    const slideInfo = slideImages[slideIndex];
    const selectedFile = imageType === 'desktop' ? slideInfo?.slideDesktopImageFile : slideInfo?.slideMobileImageFile;
    return selectedFile ? selectedFile.name : undefined;
  };

  const handlePublish = (cb: () => void) => () => {
    openModal({
      title: "Actualizar Carousel",
      description:
        "El carousel se actualizara en base a las imagenes y titulos seleccionados \n¿Deseas actualizarlo ahora?",
      acceptButtonTitle: "Publicar",
      cb,
    });
  };

  const handleFormElementsDisabled = () => {
    setDisabledFormElements(!disabledFormElements)
  }

const onClickSave = async () => {
  try {
    const response = await axios.post<PostCourseResponse>(
      `${env.API_URL}/post/carousel`,
      carousel,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (Object.keys(slideImages).length) {
      const promises: Promise<any>[] = [];

      Object.entries(slideImages).forEach(([slideIndex, slideImageInfo]) => {
        promises.push(
          new Promise(async (resolve) => {
            const slideId = _.get(response.data, `slides.${slideIndex}.id`);

            if(slideId) {
              try {

                const formData = new FormData();
                /* const options = {
                  maxSizeMB: 0.5,
                  maxWidthOrHeight: 500,
                  useWebWorker: true,
                }; */

                if (slideImageInfo.slideDesktopImageFile) {
                  /* const compressedDesktopImage = await imageCompression(
                    slideImageInfo.slideDesktopImageFile,
                    options
                  ); */
                  const compressedDesktopImage = slideImageInfo.slideDesktopImageFile
                  formData.append("desktopFile", compressedDesktopImage);
                }

                if (slideImageInfo.slideMobileImageFile) {
                  /* const compressedMobileImage = await imageCompression(
                    slideImageInfo.slideMobileImageFile,
                    options
                  ); */
                  const compressedMobileImage = slideImageInfo.slideMobileImageFile
                  formData.append("mobileFile", compressedMobileImage);
                }

                formData.append("section", path);

                await axios.post(
                  `${env.API_URL}/post/slides/${slideId}/image`,
                  formData,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                );
              } catch (error) {
              console.error(error);
              }
            }
            resolve({});
          })
        )
      });
      await Promise.all(promises);
    }
    setDisabledFormElements(!disabledFormElements);
    setforceRefresh(true);
  } catch (error) {
    console.error(error);
  }
};

const Disclaimer = styled.p`
    color: #aaaaaa;
    margin-bottom: 20px;
  `

  return(
    <>
      <Modal
        acceptButton={acceptButton}
        open={open}
        title={title}
        description={description}
        onClose={onClose}
      />
      <HomePageGrid>
        <div style={{width: "100%"}}>
          <HomeFormFlex>
            {carousel && carousel.slides.length > 0 ? (
              carousel.slides.map(({title, slideDesktopImageUrl, slideMobileImageUrl}, slideIndex)=> (
                <div
                  style={{
                    border: "1px solid #AAAAAA",
                    width: "auto",
                    height: "auto",
                    padding: "20px",
                  }}
                  key={slideIndex}
                >
                  <InputWrapper key={slideIndex}>
                    <InputLabel>Imagen Nº {slideIndex + 1}</InputLabel>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                        marginBottom: "5px"
                      }}
                    >
                      <InputWrapper style={{ flexGrow: "18" }}>
                        <Input
                          style={{ padding: "8px" }}
                          onChange={(e) =>
                            onChangeTitleField(
                              "title",
                              slideIndex,
                              e.target.value
                            )
                          }
                          placeholder="Ingresa titulo de la Imagen"
                          value={title}
                          disabled={disabledFormElements}
                        />
                      </InputWrapper>
                      <ActionButton
                        onClick={handleRemoveSlide(() =>
                          removeSlide(slideIndex)
                        )}
                        iconSrc={deleteSrc}
                        status={disabledFormElements}
                        disabled={disabledFormElements}
                      />
                    </div>
                    { slideDesktopImageUrl ?
                      (
                        <BannerCard thumbnail={slideDesktopImageUrl} style={{marginBottom: "10px"}}/>
                      ) : (
                        !(getSlidePendingImage(slideIndex, 'desktop')) ? (
                          <>
                          <div
                            style={{
                              gap: "10px",
                              width: "220px",
                              height: "50px",
                              display: "flex",
                              margin: "25px 0",
                              border: "1px solid #019af1",
                              cursor: "pointer",
                              justifyContent: "center"
                            }}
                          >
                            <FileUploadLabel
                              style={{
                                position: "relative",
                                zIndex: 1,
                                padding: "8px",
                                textDecoration: "none",
                                color: "#019af1",
                                fontSize: "18px",
                              }}
                            >
                              <HiddenInput
                                style={{
                                  padding: "8px",
                                }}
                                type="file"
                                accept="image/*"
                                onChange={handleSetSlideImageFactory(slideIndex, 'desktop')}
                                disabled={disabledFormElements}
                              />
                              Cargar imagen desktop
                            </FileUploadLabel>
                          </div>
                          <Disclaimer><small>* Se recomienda utilizar imágenes con un tamaño de 1440x500 para la versión de escritorio.</small></Disclaimer>
                          </>
                        ) : (
                          <FileDisplay
                            onClickDelete={() => handleDeleteSlideImage(slideIndex, 'desktop')}
                          >
                            {getSlidePendingImage(slideIndex, "desktop")}
                          </FileDisplay>
                        )
                      )
                    }
                    { slideMobileImageUrl ?
                      (
                        <BannerCard thumbnail={slideMobileImageUrl}/>
                      ) : (
                        !(getSlidePendingImage(slideIndex, 'mobile')) ? (
                          <>
                            <div
                              style={{
                                gap: "10px",
                                width: "220px",
                                height: "50px",
                                display: "flex",
                                margin: "25px 0",
                                border: "1px solid #019af1",
                                cursor: "pointer",
                                justifyContent: "center"
                              }}
                            >
                              <FileUploadLabel
                                style={{
                                  position: "relative",
                                  zIndex: 1,
                                  padding: "8px",
                                  textDecoration: "none",
                                  color: "#019af1",
                                  fontSize: "18px",
                                }}
                              >
                                <HiddenInput
                                  style={{
                                    padding: "8px",
                                  }}
                                  type="file"
                                  accept="image/*"
                                  onChange={handleSetSlideImageFactory(slideIndex, 'mobile')}
                                  disabled={disabledFormElements}
                                />
                                Cargar imagen mobile
                              </FileUploadLabel>
                            </div>
                              <Disclaimer><small>*Se recomienda utilizar imágenes con un tamaño de 720x620 para dispositivos móviles.</small></Disclaimer>
                          </>
                        ) : (
                          <FileDisplay
                            onClickDelete={() => handleDeleteSlideImage(slideIndex, 'mobile')}
                          >
                            {getSlidePendingImage(slideIndex, "mobile")}
                          </FileDisplay>
                        )
                      )
                    }
                  </InputWrapper>
                </div>
              ))
            ) : (
              <div style={{ display: "flex", height: "220px", alignItems: "center", justifyContent: "center", backgroundColor:"#aaaaaa"}}>
                <span style={{color: "#fff", fontSize:"35px"}}>No hay slides de carrusel para mostrar.</span>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div
              style={{
                display: "flex",
                gap: "10px"
              }}
              >
                <RoundedButton
                  filled
                  type="button"
                  onClick={() => addSlide()}
                  status={disabledFormElements}
                  disabled={disabledFormElements}
                >
                + Agregar slide
                </RoundedButton>
                <RoundedButton
                  filled
                  type="button"
                  onClick={handlePublish(()=>onClickSave())}
                  status={disabledFormElements}
                  disabled={disabledFormElements}
                >
                  Publicar
                </RoundedButton>
              </div>
              <EditButton iconSrc={editSrc} onClick={handleFormElementsDisabled}/>
            </div>
          </HomeFormFlex>
        </div>
      </HomePageGrid>
    </>
  )
}