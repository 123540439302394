import _axios from "axios";
import { env } from "./config";
import { TOKEN_STORAGE_KEY } from "./context";

const axios = _axios.create({
  baseURL: env.API_URL,
  timeout: 3000,
});

const onRequestSuccess = (config: any) => {
//   const token = localStorage.get("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
  return config;
};

const onRequestFail = (error: any) => {
  return Promise.reject(error);
};

axios.interceptors.request.use(onRequestSuccess, onRequestFail);

const onResponseSuccess = (response: any) => {
  return response;
};

const onResponseFail = (error: any) => {
  const status = error.status || error.response.status;
  if (status === 403 || status === 401) {
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    window.location.reload()
  }
  return Promise.reject(error);
};

axios.interceptors.response.use(onResponseSuccess, onResponseFail);

export default axios;
