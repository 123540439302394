import styled from "styled-components";
import certifiedCourseChipIconImgSrc from "../../assets/images/certified-icon.svg";

interface ChipProps {
  dark?: boolean;
  purple?: boolean;
}

export const Chip = styled.div<ChipProps>`
  background-color: ${({ dark }) => (dark ? "#0A2739" : "#019af1")};
  ${({ purple }) => (purple ? "background-color: #B090B6;" : "")}
  height: 45px;
  line-height: 42px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 100px;
  font-size: 0.8rem;
  vertical-align: middle;
  font-weight: 500;
  color: white;
  font-family: telefonicaBold;
  width: max-content;
`;

export const CertifiedCourseChip = styled(Chip)`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 20px;
  padding-right: 30px;
  margin-left: -20px;
  margin-bottom: 10px;
  font-family: telefonicaBold;
`;

export const CertifiedCourseChipIconImg = styled.img`
  height: 30px;
`;

export const CertifiedCourseBadge = () => (
  <CertifiedCourseChip purple>
    <CertifiedCourseChipIconImg src={certifiedCourseChipIconImgSrc} />
    CURSO CERTIFICADO
  </CertifiedCourseChip>
);
