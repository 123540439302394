import styled from "styled-components";
import pcIconSrc from "../../assets/images/pc-icon.svg";
import { Title } from "../../components";

const Background = styled.div`
  background: #0b2739;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

const PCIcon = styled.div`
  background: url(${pcIconSrc}) no-repeat center center;
  background-size: cover;
  width: 250px;
  height: 250px;
`;

export const RestrictedToPc: React.FC = () => {
  return (
    <Background>
      <PCIcon />
      <Title style={{ color: "white" }}>Ingresa desde tu PC</Title>
    </Background>
  );
};
