import styled from "styled-components";

const PageContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const PageContainerContent = styled.div`
  padding-left: 60px;
  padding-right: 60px;
  width: 1500px;
  max-width: 90vw;
  @media (max-width: 550px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
`;

interface IProps extends React.HTMLProps<HTMLDivElement> {
  contentStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

export const PageContainer: React.FC<IProps> = ({
  children,
  style,
  contentStyle,
}) => {
  return (
    <PageContainerWrapper style={style}>
      <PageContainerContent style={contentStyle}>
        {children}
      </PageContainerContent>
    </PageContainerWrapper>
  );
};
