import { useState, useEffect, useCallback } from "react";
import axios from "../../axios";
import { env } from "../../config";
import _ from "lodash";

export const useGetCourses = () => {
  const [courses, setCourses] = useState<Course[]>();
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [search, setSearch] = useState<string | null>(null);

  const fetchData = useCallback((search: string|null) => {
    (async () => {
      setCoursesLoading(true);
      let path = "";
        if (search?.length) {
          path = "/search/findByTitleIgnoreCaseContaining"
        }
      const {
        data: {
          _embedded: { courses: _courses },
        },
      } = await axios.get<CoursesResponse>(
        `${env.API_URL}/courses${path}`, {
          params: {
            projection: "courseDetailsView",
            title: search
          }
        }
      );
      setCourses(_courses);
      setCoursesLoading(false);
    })();
  }, []);

  useEffect(() => {
    fetchData(search);
  }, [fetchData, search]);

  const refreshCourses = () => fetchData(search);

  return { courses, setCourses, coursesLoading, refreshCourses, setSearch:_.debounce(setSearch, 400) };
};
