import deleteBlueSrc from "../../assets/images/delete-blue.svg";
import deleteBlackSrc from "../../assets/images/delete-black.svg";
import deleteWhiteSrc from "../../assets/images/delete-white.svg";

export const DeleteButton: React.FC<{
  onClick: () => void;
  black?: boolean;
  white?: boolean;
}> = ({ onClick, black, white }) => (
  <img
    src={black ? deleteBlackSrc : white ? deleteWhiteSrc : deleteBlueSrc}
    style={{ height: "1.4rem", cursor: "pointer" }}
    onClick={onClick}
    alt="delete button"
  />
);
