import { useState, useEffect } from "react";
import axios from "../../axios";
import { env } from "../../config";

export const useGetCategories = () => {
  const [categories, setCategories] = useState<Category[]>();
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [forceRefresh, setforceRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setCategoriesLoading(true);
      try {
        const {
          data: {
            _embedded: { categories: _categories },
          },
        } = await axios.get<CategoriesResponse>(
          `${env.API_URL}/categories`
        );
        setCategories(_categories);
        setCategoriesLoading(false);
        setforceRefresh(false)
      }
      catch(err){
        console.log(err)
        setCategoriesLoading(false);
      }
    }
    fetchData();

  }, [forceRefresh]);
  return { categories, setCategories, categoriesLoading, setforceRefresh, forceRefresh };
};
