import axios from "../../axios";
import { env } from "../../config";
import { useState, useEffect, useCallback } from "react";
import _ from "lodash";

type GetRecordedCoursesConfig = {
  projection: string;
};

export const useGetRecordedCourses = ({
  projection,
}: GetRecordedCoursesConfig) => {
  const [recordedCourses, setRecordedCourses] = useState<RecordedCourse[]>();
  const [recordedCoursesLoading, setRecordedCoursesLoading] = useState(true);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [search, setSearch] = useState<string | null>(null);

  const fetchData = useCallback(
    (categoryId: number | null, search: string | null) => {
      (async () => {
        setRecordedCoursesLoading(true);

        //this is a mess too
        let path = "";
        if (categoryId&&!search?.length) {
          path = "/search/findByCategoryId"
        }
        if (!categoryId&&search?.length) {
          path = "/search/findByTitleIgnoreCaseContaining"
        }
        if (categoryId&&search?.length){
          path = "/search/findByTitleIgnoreCaseContainingAndCategoryId"
        }
        
        const {
          data: {
            _embedded: { recordedCourses: _recordedCourses },
          },
        } = await axios.get<RecordedCoursesResponse>(
          `${env.API_URL}/recordedCourses${path}`,
          {
            params: {
              categoryId,
              title: search,
              projection,
            },
          }
        );
        setRecordedCourses(_recordedCourses);
        setRecordedCoursesLoading(false);
      })();
    },
    [projection]
  );

  useEffect(() => {
    fetchData(categoryId, search);
  }, [search, categoryId, fetchData]);

  const refreshCourses = () => fetchData(categoryId, search);

  return {
    recordedCourses,
    setRecordedCourses,
    recordedCoursesLoading,
    setCategoryId,
    refreshCourses,
    setSearch:_.debounce(setSearch, 400),
    search,
  };
};
